import React, { Component } from 'react';

class Ticker extends Component {
    render() {
        return (
            <div className='ticker-container'>
                <div className='ticker-title'>{this.props.tik1}/{this.props.tik2}</div>
                <div className='asks'>
                <div className='asksPrice'>Price({this.props.tik2})</div>
                <div className='asksAmount'>Amount({this.props.tik1})</div>
                <div className='asksTotal'>Total</div>
                {this.props.asks.reverse().map((item, index) => {
                    return <div className='itemAsks' key={index}>
                            <div className='itemAsksPrice'>{parseFloat(item[0]).toFixed(6)}</div>
                            <div className='itemAsksAmount'>{parseFloat(item[1]).toFixed(6)}</div>
                            <div className='itemAsksTotal'>{parseFloat(item[0] * item[1]).toFixed(6)}</div>
                        </div>
                })}
                </div>
                <div className='price'>{parseFloat(this.props.price).toFixed(6)}</div>
                <div className='bids'>
                {this.props.bids.map((item, index) => {
                    return <div className='itemAsks' key={index}>
                            <div className='itemBidsPrice'>{parseFloat(item[0]).toFixed(6)}</div>
                            <div className='itemBidsAmount'>{parseFloat(item[1]).toFixed(6)}</div>
                            <div className='itemBidsTotal'>{parseFloat(item[0] * item[1]).toFixed(6)}</div>
                        </div>
                })}
                </div>
                <div className='calculations'>
                    <div className='calculations-quantity-title'>Quantity</div>
                    <div className='calculations-quantity'>{this.props.quantity}</div>
                </div>
            </div>
        )}
}

export default Ticker;