import React, { Component } from 'react';
import './App.css';
import Ticker from './Ticker.js';

class App extends Component {
  componentDidMount() {
    let ws1 = new WebSocket('wss://stream.binance.com:9443/ws/linkusdt@trade/linkusdt@depth20');
    ws1.onmessage = (event) => {
      let stockObject = JSON.parse(event.data);
      if(stockObject.e) {
        this.setState({price1: stockObject.p});
      } else {
        this.setState({asks1: stockObject.asks, bids1: stockObject.bids});
        this.calculate1(stockObject.asks);
      }
    }

    let ws2 = new WebSocket('wss://stream.binance.com:9443/ws/linketh@trade/linketh@depth20');
    ws2.onmessage = (event) => {
      let stockObject = JSON.parse(event.data);
      if(stockObject.e) {
        this.setState({price2: stockObject.p});
      } else {
        this.setState({asks2: stockObject.asks, bids2: stockObject.bids});
        this.calculate2(stockObject.bids);
      }
    }

    let ws3 = new WebSocket('wss://stream.binance.com:9443/ws/ethuah@trade/ethuah@depth20');
    ws3.onmessage = (event) => {
      let stockObject = JSON.parse(event.data);
      if(stockObject.e) {
        this.setState({price3: stockObject.p});
      } else {
        this.setState({asks3: stockObject.asks, bids3: stockObject.bids});
        this.calculate3(stockObject.bids);
      }
    }

    let ws4 = new WebSocket('wss://stream.binance.com:9443/ws/usdtuah@trade/usdtuah@depth20');
    ws4.onmessage = (event) => {
      let stockObject = JSON.parse(event.data);
      if(stockObject.e) {
        this.setState({price4: stockObject.p});
      } else {
        this.setState({asks4: stockObject.asks, bids4: stockObject.bids});
        this.calculate4(stockObject.asks);
      }
    }
  }

  sendAPICall() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-MBX-APIKEY", "unv8GDtkkibNOjoBc1U5DD5K9CIOdPbG1djlNdSYG9l7dBij02oeKXwGwu2z08GZ");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("https://testnet.binance.vision/api/v3/order?symbol=BNBUSDT&side=SELL&type=&timestamp=1668381408924&signature=93819d47c8e325f5e35b37f4372511bd5b02eb03e122e15b0df738e6418e31b2", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

  calculate1(asks) {
    let myMoney = 100;
    let linkAmount = 0;
    asks.map((item, index) => {
      if(myMoney > 0) {
        let total = item[0] * item[1];
        // last time coming in, example total=250 myMoney=200
        if (total >= myMoney) {
          linkAmount += parseFloat(myMoney / item[0]);
          myMoney = 0;
        } else {
          // i'll have more money after this total is lower than my money total=150 myMoney=200
          linkAmount += parseFloat(total / item[0]);
          myMoney -= total;
        }
      }
    })

    this.setState({quantity1: parseFloat(linkAmount).toFixed(6)});
    this.calculate2(this.state.bids2);
  }

  calculate2(bids) {
    let myMoney = this.state.quantity1;
    let ethAmount = 0;
    bids.map((item, index) => {
      if(myMoney > 0) {
        let total = item[1];
        // last time coming in, example total=250 myMoney=200
        if (total >= myMoney) {
          ethAmount += parseFloat(myMoney * item[0]);
          myMoney = 0;
        } else {
          // i'll have more money after this total is lower than my money total=150 myMoney=200
          ethAmount += parseFloat(item[0] * item[1]);
          myMoney -= total;
        }
      }
    })

    this.setState({quantity2: parseFloat(ethAmount).toFixed(6)});
    this.calculate3(this.state.bids3);
  }

  calculate3(bids) {
    let myMoney = this.state.quantity2;
    let uahAmount = 0;
    bids.map((item, index) => {
      if(myMoney > 0) {
        let total = item[0] * item[1];
        // last time coming in, example total=250 myMoney=200
        if (total >= myMoney) {
          uahAmount += parseFloat(myMoney * item[0]);
          myMoney = 0;
        } else {
          // i'll have more money after this total is lower than my money total=150 myMoney=200
          uahAmount += parseFloat(total * item[0]);
          myMoney -= total;
        }
      }
    })

    this.setState({quantity3: parseFloat(uahAmount).toFixed(6)});
    this.calculate4(this.state.asks4);
  }

  calculate4(asks) {
    let myMoney = this.state.quantity3;
    let usdtAmount = 0;
    asks.map((item, index) => {
      if(myMoney > 0) {
        let total = item[0] * item[1];
        // last time coming in, example total=250 myMoney=200
        if (total >= myMoney) {
          usdtAmount += parseFloat(myMoney / item[0]);
          myMoney = 0;
        } else {
          // i'll have more money after this total is lower than my money total=150 myMoney=200
          usdtAmount += parseFloat(total / item[0]);
          myMoney -= total;
        }
      }
    })

    this.setState({quantity4: parseFloat(usdtAmount).toFixed(6)});
  }

  calc() {
    const node = document.createElement("div");
    node.className = "overT";
    const date = new Date().toLocaleString() + "";
    node.innerText = this.state.quantity4 + " ----- " + date;
    document.body.appendChild(node);
  }

  constructor(props) {
    super(props)
    this.state = {
      price1: null,
      asks1: [],
      bids1: [],
      quantity1: null,
      price2: null,
      asks2: [],
      bids2: [],
      quantity2: null,
      price3: null,
      asks3: [],
      bids3: [],
      quantity3: null,
      price4: null,
      asks4: [],
      bids4: [],
      quantity4: null,
    }

    this.calc = this.calc.bind(this);
  }

  render() {
    return (
        <div>
          <Ticker tik1="LINK" tik2="USDT" quantity={this.state.quantity1} price={this.state.price1} asks={this.state.asks1} bids={this.state.bids1} />
          <Ticker tik1="LINK" tik2="ETH" quantity={this.state.quantity2} price={this.state.price2} asks={this.state.asks2} bids={this.state.bids2} />
          <Ticker tik1="ETH" tik2="UAH" quantity={this.state.quantity3} price={this.state.price3} asks={this.state.asks3} bids={this.state.bids3} />
          <Ticker tik1="USDT" tik2="UAH" quantity={this.state.quantity4} price={this.state.price4} asks={this.state.asks4} bids={this.state.bids4} />
          <div className='amountInfoContainer'>
            <div className='amountInfo'>Start Amount: <div className='amountInfoBold'>$100</div></div>
            <div className='amountInfo'>Average End Amount: {parseFloat((((100/this.state.price1) * (this.state.price2)) * this.state.price3) / this.state.price4).toFixed(4) > 100 ? <div className='amountInfoGreen'>{parseFloat((((100/this.state.price1) * (this.state.price2)) * this.state.price3) / this.state.price4).toFixed(4)}</div> : <div className='amountInfoRed'>{parseFloat((((100/this.state.price1) * (this.state.price2)) * this.state.price3) / this.state.price4).toFixed(4)}</div>}</div>
            <div className='amountInfo'>End Amount: {parseFloat(this.state.quantity4).toFixed(4) > 100 ? <div className='amountInfoGreen'>{parseFloat(this.state.quantity4).toFixed(4)}</div> : <div className='amountInfoRed'>{parseFloat(this.state.quantity4).toFixed(4)}</div>}</div>
            <div className='amountInfo'>Difference: <div className='amountInfoBold'>{parseFloat(((((100/this.state.price1) * (this.state.price2)) * this.state.price3) / this.state.price4) - this.state.quantity4).toFixed(4)}</div></div>
          </div>
          {this.state.quantity4 > 100.5 && this.state.quantity4 < 110 ? this.calc() : <div></div>}
        </div>
      );
  }
}

export default App;
